import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';

import { GalleryApi, ProductApi } from '../../../apis';
import { AnimationOnScroll, Pagination, ProductCard } from '../../../components';
import { IProduct, ISortOrder } from '../../../shared/interfaces';
import { IGallery } from '../../../shared/interfaces/gallery.interface';
import { ProductModel } from '../../../shared/models';
import './styles.scss';

const CntPerPage = 15;

export const GalleryDetailPage: FC = () => {
  const [gallery, setGallery] = useState<IGallery>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<ISortOrder>(ISortOrder.ASC);

  const { galleryId } = useParams<{ galleryId: string }>();

  const fetchProducts = useCallback(() => {
    ProductApi.readAll({
      query: {
        galleryId
      },
      options: {
        limit: CntPerPage,
        skip: (pageNumber - 1) * CntPerPage,
        sort: {
          price: sortOrder
        }
      }
    })
      .then((res) => {
        const { products, pagination } = res;

          products.map((obj: any) => {
              obj.name.replace('nft', 'music');
              obj.name.replace('minting', '');
              obj.description.replace('nft', 'music');
              obj.description.replace('minting', '');

              return obj;
          });

        setProducts(products.map((product: IProduct) => new ProductModel(product)));
        setTotalCnt(pagination.total);
      })
      .catch((err) => {
        console.log('🚀 ~ file: index.tsx:91 ~ fetchProducts ~ err:', err);
      });
  }, [pageNumber, sortOrder]);

  useEffect(() => {
    if (galleryId) fetchProducts();
  }, [galleryId, fetchProducts]);

  useEffect(() => {
    GalleryApi.readById(galleryId).then((res) => {
      setGallery(res.gallery);
    });
  }, [galleryId]);

  return (
    <div className="artist-detail-page">
      <div className="banner-wrapper">
        <div className="banner-container">
          <Carousel autoPlay={true} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true}>
            {new Array(3).fill(1).map((item, index) => (
              <img key={index} src="/images/artists/banner.png" alt="banner" />
            ))}
          </Carousel>
        </div>
        <div className="artist-image-wrapper">
          <div className="artist-image-container">
            <img src={gallery?.thumbnail} alt="artist_thumbnail" />
          </div>
        </div>
      </div>
      <div className="artist-detail-container">
        <div className="artist-info text--white">
          <div className="artist-header-container">
            <h1 className="text-heading2">{gallery?.name}</h1>
          </div>
          <p className="artist-joined-at text-body4"> {moment(gallery?.createdAt).format('DD MMMM YYYY')}</p>
          <p className="artist-description text-body3">{gallery?.description}</p>
        </div>
      </div>

      <div className="content">
        <div className="products">
          {products.length > 0 &&
            products.map((product) => (
              <AnimationOnScroll key={product.id} animation="animate__fadeIn" isSubElement>
                <ProductCard key={product.id} product={product} />
              </AnimationOnScroll>
            ))}
        </div>
        <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
      </div>
    </div>
  );
};
