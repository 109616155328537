import React, { ChangeEvent, FC, useEffect, useState, useRef } from 'react';
import { FormControl, FormHelperText, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/core';
import useOnClickOutside from '../OutSideClickHandler';
import './styles.scss';
import classnames from 'classnames';
import { Icon } from '../Icon';
import Calendar from '../Calendar';
import moment from 'moment';

export interface IOption {
  label: string;
  value: any;
}
interface ITextFieldProps {
  name?: string;
  value: string;
  label: string;
  isInvalid?: boolean;
  helperText?: string;
  type?: 'text' | 'password' | 'select' | 'date';
  visiblePassword?: boolean;
  options?: IOption[];
  onChange?: (e: ChangeEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  onToggleVisiblePassword?: () => void;
  handleText?: string;
}

const TextField: FC<ITextFieldProps> = ({
  type = 'text',
  name,
  value,
  label,
  options,
  isInvalid,
  helperText,
  visiblePassword,
  onChange,
  onBlur,
  onToggleVisiblePassword,
  handleText
  }) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IOption>();
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [visibleOptions, setVisibleOptions] = useState<boolean>(false);
  const nationalityRef = useRef<HTMLDivElement>(null);
  const birthRef = useRef<HTMLDivElement>(null);
  
  const handleFocus = () => {
    setFocused(true);
  };
  
  const handleBlur = (e: any) => {
    console.log(e.target.value);
    setFocused(false);
    if (onBlur) onBlur(e);
  };
  
  const handleClick = () => {
    setVisibleOptions(!visibleOptions);
    setFocused(!focused);
  };
  
  const handleSelect = (value: IOption) => {
    console.log(value);
    if (onChange)
      onChange({
        currentTarget: {
          name: name,
          value: value.value
        }
      } as unknown as ChangeEvent);
    setSelectedItem(value);
    setFocused(false);
    setVisibleOptions(false);
  };
  const handleSelectDate = (date: Date, visible: boolean) => {
    if (date) {
      setSelectedDate(date);
    }
    
    setVisibleOptions(visible);
    if (onChange)
      onChange({
        currentTarget: {
          name: name,
          value: date
        }
      } as unknown as ChangeEvent);
    setFocused(false);
  }
  
  useEffect(() => {
    const item = options?.find(({ value: v }) => value === v);
    setSelectedItem(item);
    if (value) setSelectedDate(new Date(value));
  }, [value, options]);
  
  const getFullDate = (date: Date | undefined | null) => {
    if (date) {
      return moment(date).format('YYYY/MM/DD');
    }
    return
  }
  
  useOnClickOutside(nationalityRef, () => {
    setVisibleOptions(false);
    setFocused(false);
  });
  
  useOnClickOutside(birthRef, () => {
    setVisibleOptions(false);
    setFocused(false);
  });
  
  return (
    <FormControl
      className={classnames('d-text-field', {
        'd-text-field--empty': !focused && !value,
        'd-text-field--error': isInvalid,
        'd-text-field--focused': focused
      })}
      isInvalid={isInvalid}
    >
      <div className="form-filled-wrapper">
        <FormLabel className="form-label">{label}</FormLabel>
        {type === 'select' && (
          <div ref={nationalityRef}>
            <InputGroup>
              <div className="form-select-input" onClick={handleClick} onBlur={handleBlur}>
                {selectedItem?.label}
              </div>
              <InputRightElement className="form-right-element">
                <Icon name="arrow-drop-down" />
              </InputRightElement>
            </InputGroup>
            {visibleOptions && options && (
              <div className="form-select-options">
                {options.map((item, index) => (
                  <div key={index} className="form-select-option" onClick={() => handleSelect(item)}>
                    {item.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) }
        {type === 'date' && (
          <div ref={birthRef}>
            <InputGroup>
              <div className="form-select-input" onClick={handleClick} onBlur={handleBlur}>
                {getFullDate(selectedDate)}
              </div>
              <InputRightElement className="form-right-element">
                <Icon name="arrow-drop-down" />
              </InputRightElement>
            </InputGroup>
            {visibleOptions &&  (
              <Calendar selectDate={handleSelectDate} dateProp={selectedDate ? new Date(selectedDate): new Date()}/>
            )}
          </div>
        ) }
        { type !== 'select' && type !== 'date' && (
          // <InputGroup style={{marginTop: "-12px"}}>
          <InputGroup>
            <Input
              name={name}
              value={value}
              className={classnames('form-input', { 'with-right-element': onToggleVisiblePassword })}
              type={visiblePassword ? 'text' : type === 'password' ? 'password' : type}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {onToggleVisiblePassword && (
              <InputRightElement className="form-right-element" onClick={onToggleVisiblePassword}>
                <Icon name={visiblePassword ? 'eye-off' : 'eye'} />
              </InputRightElement>
            )}
            {
              handleText && <div className="handle-text">
                {handleText}
              </div>
            }
          </InputGroup>
        )}
      </div>
      <FormHelperText className="form-helper-text">{helperText}</FormHelperText>
    </FormControl>
  );
};

export default TextField;
