// Dependencies
import classnames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { AnimationOnScroll, AudioPlayer, CartButton, IconButton, ObjectRender } from '../../../components';
// Share
import { CURRENCY, PRODUCT_TYPE } from '../../../shared/enums';
import { ProductModel } from '../../../shared/models';
// Utils
import { getRandomGradientClass, plainToHtml } from '../../../utils';
// Styles
import './styles.scss';

// Props
interface ProductDetailSongPageProps {
  product: ProductModel;
  handleToggleFavorite?: () => void;
  handleNext?: () => void;
  isFavorite?: boolean;
}

// Render
export const ProductDetailSongPage: FC<ProductDetailSongPageProps> = ({
  product,
  handleToggleFavorite,
  isFavorite,
  handleNext
}) => {
  const { t } = useTranslation();

  return (
    <div className="common-product-detail-page">
      <div className="common-product-content">
        <div className={`ticket-content ticket-content--${product.type}`}>
          <AnimationOnScroll
            animation="animate__backInRight"
            delay={1}
            className={`ticket-left ticket-left--${product.type}`}
          >
            <div className="ticket-song-content">
              <div className="ticket-song-header">
                <h2 className="text-heading2 text--lime song-title">{product.name}</h2>
                <div className="song-type">
                  {product.category.map((item, index) => (
                    <span key={index} className="text-body1 text-bold text--cyan">
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
              {product.type === PRODUCT_TYPE.SONG && (
                <div className="audio-wrapper">
                  <AudioPlayer src={product.getPreviewSongUrl} />{' '}
                </div>
              )}

              <div className="cart">
                <CartButton
                  color="lime"
                  productId={product.id as string}
                  productPrice={product.price as number}
                  productCurrency={product.currency as CURRENCY}
                />
                <IconButton
                  icon="heart"
                  className={classnames('favorite-button', {
                    'favorite-button--active': isFavorite
                  })}
                  onClick={handleToggleFavorite}
                />
                <IconButton icon="share" />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animation="animate__backInRight"
            delay={1.5}
            className={`ticket-right ticket-right--${product.type}`}
          >
            <div className={`ticket-image ${product.type !== 'object' && getRandomGradientClass()}}`}>
              <div className={product.type === 'video' ? 'ticket-video-content' : 'ticket-image-content'}>
                {product.type === 'video' ? (
                  <video src={product.getVideoUrl} controls />
                ) : product.type === 'object' ? (
                  <ObjectRender
                    id={product.id}
                    fieldname={product.object?.fieldname}
                    filename={product.object?.filename}
                    canAction
                  />
                ) : (
                  <img src={product?.getThumbnailUrl} alt="not found" />
                )}
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll className="next-btn-wrapper" animation="animate__slideInRight" delay={2.5}>
            <img src={product?.nextProductAvatarUrl} alt="thumbnail-mask" />
            <IconButton icon="arrow-rectangle" className="next-button" onClick={handleNext} />
          </AnimationOnScroll>
        </div>
        <div className="ticket-content x">
          <AnimationOnScroll className="ticket-info description-content" animation="animate__backInRight" delay={2}>
            <div className="description">
              <span className="text-heading3 text--white">{t('Common.Description.')}</span>
              <div
                className="text-body1 text--white description-text"
                dangerouslySetInnerHTML={{ __html: plainToHtml(product?.description || '') }}
              />
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};
