import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import casperImage from '../../../assets/images/Casper_Wordmark_White_RGB.png';
import { ROUTES } from '../../../constants';
import { Icon } from '../../Icon';
import './styles.scss';

export const MainFooter: FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="main-footer">
      <div className="footer-top">
        <div className="social">
          <Link to="/" className="logo">
            VeepMusic
          </Link>
          <span className="text-body1 description">
            {t(
              'Layout.Welcome to our online marketplace where you can buy and collect unique Music Items.Our carefully curated selection features rare and exclusive tracks from talented musicians and producers around the world.'
            )}
          </span>
          <div className="social-link-group">
            <Link className="social-link" to="#">
              <Icon name="spotify" />
            </Link>
            <Link className="social-link" to="#">
              <Icon name="youtube" />
            </Link>
            <Link className="social-link" to="#">
              <Icon name="facebook" />
            </Link>
            <Link className="social-link" to="#">
              <Icon name="instagram" />
            </Link>
          </div>
        </div>
        <div className="footer-category">
          <div className="footer-category-item">
            <Link to="#" className="text-heading4 text-bold">
              {t('Layout.Marketplace')}
            </Link>
            <Link to={ROUTES.PRODUCTS.INDEX} className="text-body1">
              {t('Layout.All Items')}
            </Link>
            <Link to={ROUTES.EVENTS.INDEX} className="text-body1">
              {t('Layout.Event Tickets')}
            </Link>
            <Link to={ROUTES.PRODUCTS.INDEX} className="text-body1">
              {t('Layout.Categories')}
            </Link>
          </div>
          <div className="footer-category-item">
            <Link to="#" className="text-heading4 text-bold">
              {t('Layout.My Account')}
            </Link>
            <Link to={ROUTES.PROFILE.PREFIX} className="text-body1">
              {t('Layout.Profile')}
            </Link>
            <Link to={ROUTES.PROFILE.LIKED_PRODUCTS} className="text-body1">
              {t('Layout.Favorites')}
            </Link>
            {/* <Link to={ROUTES.PROFILE.PAYMENT_METHODS} className="text-body1">Payment Methods</Link> */}
            <Link to={ROUTES.PROFILE.SETTINGS} className="text-body1">
              {t('Layout.Settings')}
            </Link>
          </div>
          <div className="footer-category-item">
            <Link to="#" className="text-heading4 text-bold">
              {t('Layout.Resources')}
            </Link>
            <Link to={ROUTES.HELP_CENTER.INDEX} className="text-body1">
              {t('Layout.Help Center')}
            </Link>
            <Link to="#" className="text-body1">
              {t('Layout.Blog')}
            </Link>
            <Link to={ROUTES.ARTICLE.LIST} className="text-body1">
              {t('Layout.Newsletter')}
            </Link>
            <Link to="#" className="text-heading4 text-bold company">
              {t('Layout.Company')}
            </Link>
            <Link to={ROUTES.US} className="text-body1">
              {t('Layout.About')}
            </Link>
            <Link to="#" className="text-body1">
              {t('Layout.Careers')}
            </Link>
          </div>
        </div>
      </div>
      <div className="casper">
        <div className="inner-link-group">
          <Link className="text-body1" to="#">
            {t('Layout.Copyright')} @ VeepMusic.com
          </Link>
          <Link className="text-body1" to="#">
            info@veepmusic.com
          </Link>
        </div>
        <div className="inner-link-group">
          <Link className="text-body1" to={ROUTES.PRIVACY_STATEMENT}>
            {t('Layout.Privacy Statement')}
          </Link>
          <Link className="text-body1" to={ROUTES.TERMS_CONDITIONS}>
            {t('Layout.Terms & Conditions')}
          </Link>
        </div>
      </div>
    </footer>
  );
};
