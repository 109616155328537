// Dependencies
import classnames from 'classnames';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { AnimationOnScroll, CartButton, IconButton } from '../../../components';
// Share
import { CURRENCY } from '../../../shared/enums';
import { ProductModel } from '../../../shared/models';
import { plainToHtml } from '../../../utils';
// Styles
import './styles.scss';

// Props
interface ProductDetailPageProps {
  product: ProductModel;
  handleToggleFavorite?: () => void;
  handleNext?: () => void;
  isFavorite?: boolean;
}

// Render
export const ProductDetailEventPage: FC<ProductDetailPageProps> = ({
  product,
  handleToggleFavorite,
  handleNext,
  isFavorite
}) => {
  const { t } = useTranslation();

  return (
    <div className="product-detail-event-page">
      <div className="product-event-content">
        <div className="ticket-content">
          <AnimationOnScroll animation="animate__backInRight" delay={1} className="ticket-info ticket-image">
            <div className="ticket-image-content">
              <img src={product?.getThumbnailUrl} alt="not found"/>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animation="animate__backInRight" delay={1.5} className="ticket-info">
            <div className="ticket-info-content">
              <h2 className="text-heading2 text--cyan">{product.name}</h2>
              <div className="event-type">
                {product.category.map((item, index) => (
                  <span key={index} className="text-body1 text-bold text--cyan">
                    {item.name}
                  </span>
                ))}
              </div>
              <div className="cart">
                <div className="cart-content">
                  <CartButton
                    color="cyan"
                    productId={product.id as string}
                    productPrice={product.price as number}
                    productCurrency={product.currency as CURRENCY}
                  />
                  <IconButton
                    icon="heart"
                    className={classnames('favorite-button', {
                      'favorite-button--active': isFavorite
                    })}
                    onClick={handleToggleFavorite}
                  />
                  <IconButton icon="share" />
                </div>
                <div className="cart-title">
                  <span className="text-body1">{moment(product.createdAt).format('ddd, MMMM DD YYYY')}</span>
                  <span className="text-body1">
                    {moment(product?.startTime).format('HH:mm')} to {moment(product?.endTime).format('HH:mm')} (CEST)
                  </span>
                  <span className="text-body1">{product.location}</span>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll className="next-btn-wrapper" animation="animate__slideInRight" delay={2.5}>
            <img src={product?.nextProductAvatarUrl} alt="thumbnail-mask" />
            <IconButton icon="arrow-rectangle" className="next-button" onClick={handleNext} />
          </AnimationOnScroll>
        </div>
        <div className="ticket-content">
          <AnimationOnScroll className="ticket-info" animation="animate__backInRight" delay={2}>
            <div className="ticket-info-content description">
              <span className="text-heading3 text--white">{t('Common.Description.')}</span>
              <div
                className="text-body1 text--white description-content"
                dangerouslySetInnerHTML={{ __html: plainToHtml(product?.description || '') }}
              />
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};
