import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ArticleApi } from '../../../apis';
import { Pagination } from '../../../components';
import { REACT_APP_API_ASSET_SERVER, ROUTES } from '../../../constants';
import i18n from '../../../i18n';
import { IArticle } from '../../../shared/interfaces';
import './styles.scss';

const pageLimit = 4;

export const ArticleListPage: FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [pageCnt, setPageCnt] = useState(0);

  const { t } = useTranslation();

  const history = useHistory();

  const handleGoToDetail = (id: string) => () => {
    history.push(ROUTES.ARTICLE.DETAIL.replace(':id', id));
  };

  useEffect(() => {
    ArticleApi.readAll({
      options: {
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
        isFeatured: true
      }
    })
      .then((res) => {
        setArticles(res.articles);
        setPageCnt(Math.ceil(res.pagination.total / pageLimit));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pageNumber]);

  return (
    <div className="article-list-page">
      <div className="page-title">
        <h2 className="text-heading2">{t('Articles.NEWS & Articles')}</h2>
      </div>
      <div className="content">
        {articles.map(({ id, description, thumbnail, createdAt, author }, index) => (
          <div key={`item-${index}`} className="article-list-page-content">
            <div className="article-list-page-content-image" onClick={handleGoToDetail(id)}>
              <img
                src={`${REACT_APP_API_ASSET_SERVER}/${thumbnail?.fieldname}/${thumbnail?.filename}`}
                alt="thumbnail"
              />
            </div>
            <div className="article-list-page-content-text">
              <span className="text-body1 mt-20">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*@ts-ignore*/}
                {description[i18n.language].length > 120 ? `${description[i18n.language].slice(0, 120)}...` : description[i18n.language]}
              </span>
              <span className="text-body2 text--cyan mt-20">{moment(createdAt).format('DD MMMM YYYY')}</span>
            </div>
          </div>
        ))}
        <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
      </div>
    </div>
  );
};
