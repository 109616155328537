export const CITIES = [
  {
    label: 'CITY1',
    value: 'CITY1'
  },
  {
    label: 'CITY2',
    value: 'CITY2',
  },
  
  {
    label: 'CITY3',
    value: 'CITY3',
  }
];
