import React, { useEffect, useState } from 'react';

import MonthYearBar from "./MonthYearBar";
import CalendarHead from "./CalendarHead";
import CalendarBody from "./CalendarBody";
import YearListBody from "./YearListBody";
import './style.scss';

export interface IRCCalendarProps {
    dateProp?: Date | null,
  selectDate: (date: Date, visible: boolean) => void
}

function Calendar({
  dateProp,
  selectDate,
    }: IRCCalendarProps) {

    const [currentDate, setCurrentDate] = useState<Date | null | undefined>(dateProp);
    const [isYearSetListShow, setIsYearSetListShow] = useState<boolean>(false);
    // const [current, setCurrent] = useState<{year?: number, month?: number, day?: number}>({});

    const setCurrentYear = (_currentYear: number) => {
        let _currentMonth = currentDate?.getMonth();
        let _currentDay = currentDate?.getDate();
        const visible = true
        
        if (_currentDay && _currentMonth) {
          setCurrentDate(new Date(_currentYear, _currentMonth, _currentDay));
          selectDate(new Date(_currentYear, _currentMonth + 1, _currentDay), visible);
        }
        setIsYearSetListShow(false);
      
    }

    const getCurrentYear = (): number => {
      if (currentDate) {
        return currentDate.getFullYear();
      }
      return 0;
    }

    const setCurrentMonth = (_currentMonth: number) => {
      let _currentYear = currentDate?.getFullYear();
      let _currentDay = currentDate?.getDate();
      const visible = true;
      _currentYear && _currentDay && setCurrentDate(new Date(_currentYear, _currentMonth, _currentDay));
      _currentYear && _currentDay && selectDate(new Date(_currentYear, _currentMonth + 1, _currentDay), visible);
    }

    const getCurrentMonth = (): number => {
        return currentDate?.getMonth() || 0;
    }

    const setCurrentDay = (_currentDay: number) => {
      const visible = false;
      let _currentYear = currentDate?.getFullYear();
      let _currentMonth = currentDate?.getMonth();
      _currentYear && _currentMonth && setCurrentDate(new Date(_currentYear, _currentMonth + 1, _currentDay));
      _currentYear && _currentMonth && selectDate(new Date(_currentYear, _currentMonth + 1, _currentDay), visible);
    }
    
    // useEffect(() => {
    //   console.log(currentDate);
    // }, [currentDate])

    const getCurrentDay = (): number => {
        return currentDate?.getDate() || 0;
    }
    
    const isSetYearList = () => {
        setIsYearSetListShow(!isYearSetListShow);
    }
    
    const getMonthDays = (): number => {
        let monthDays: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let currentYear: number = getCurrentYear();
        let currentMonth: number = getCurrentMonth();
        if (isCurrentLinearYear(currentYear)) {
            monthDays[currentMonth] = 29;
        }
        return monthDays[currentMonth];
    }
    
    const getCurrentWeekday = (): number => {
        const firstDayOfMonth: Date = new Date(currentDate?.getFullYear() || new Date().getFullYear(), currentDate?.getMonth() || new Date().getMonth(), 1);
        return firstDayOfMonth.getDay();
    }
    
    const isCurrentLinearYear = (_currentYear: number): boolean => {
        if (_currentYear % 400 === 0) {
            return true;
        } else if (_currentYear % 400 !== 0 && _currentYear % 100 === 0) {
            return true;
        } else if (_currentYear % 100 !== 0 && _currentYear % 4 === 0) {
            return true;
        }
        return false;
    }

    return (
        <div className="calendar-container">
            <MonthYearBar
                yearProp={getCurrentYear()}
                monthProp={getCurrentMonth()}
                setYearCallback={setCurrentYear}
                setMonthCallback={setCurrentMonth}
                isSetYearList={isSetYearList}
            />
            {!isYearSetListShow && <CalendarHead/>}
            <div className="divider" />
            {!isYearSetListShow && (
              <CalendarBody
              yearProp={getCurrentYear()}
              monthProp={getCurrentMonth()}
              currentDate={currentDate}
              dayProp={getCurrentDay()}
              daysProp={getMonthDays()}
              weekdayProp={getCurrentWeekday()}
              setDayCallback={setCurrentDay}
            />)}
            {
                isYearSetListShow && (
                  <YearListBody
                    yearProp={getCurrentYear()}
                    setYearCallback={setCurrentYear}
  
                  />
                )
            }
            <div className="pointer-triangle"></div>
            
        </div>
    );
}

export default Calendar;
