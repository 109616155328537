// Dependencies
import { createStore } from 'redux';

// Root reducer
import rootReducer from './reducers';

// Create store
const store = createStore(rootReducer);

// Export store
export default store;
