export const CURRENCIES = [
  {
    label: 'USD',
    value: '$'
  },
  {
    label: 'CNY',
    value: '¥'
  },
  {
    label: 'EUR',
    value: '€'
  },
  {
    label: 'JPY',
    value: '¥'
  },
  {
    label: 'AED',
    value: 'د.إ'
  },
  {
    label: 'AUD',
    value: '$‎'
  },
  {
    label: 'GBP',
    value: '£'
  }
];
