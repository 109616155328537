import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ArtistApi } from '../../../apis';
import { Icon, Pagination } from '../../../components';
import { ROUTES } from '../../../constants';
import { IArtist } from '../../../shared/interfaces';
import './styles.scss';

const pageLimit = 12;

export const ArtistListPage: FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [artists, setArtists] = useState<IArtist[]>([]);
  const [pageCnt, setPageCnt] = useState(0);

  const history = useHistory();
  const { t } = useTranslation();

  const handleGoToDetail = (id: string) => () => {
    history.push(ROUTES.ARTIST.DETAIL.replace(':id', id));
  };

  useEffect(() => {
    ArtistApi.readAll({
      options: {
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
        isFeatured: true
      }
    })
      .then((res) => {
        setArtists(res.artists);
        setPageCnt(Math.ceil(res.pagination.total / pageLimit));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pageNumber]);

  return (
    <div className="artist-list-page">
      <div className="page-title">
        <h2 className="text-heading2">{t('Artists.Artists')}</h2>
      </div>
      <div className="content">
        <div className="artists">
          {artists.map(({ id, name, thumbnail }, index) => (
            <div key={`item-${index}`} className="artist-card">
              <div className="artist-image-container" onClick={handleGoToDetail(id)}>
                <img src={thumbnail} alt="thumbnail" />
              </div>
              <div className="artist-name-container">
                <p className="artist-name">
                  {name}
                  <Icon name="star-check" className="star-check-icon" />
                </p>
                <p>50k NFTs created </p>
              </div>
            </div>
          ))}
        </div>
        <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
      </div>
    </div>
  );
};
