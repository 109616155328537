import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AudioPlayer, CartButton } from '../../../../components';
import { REACT_APP_API_ASSET_SERVER, ROUTES } from '../../../../constants';
import { CURRENCY } from '../../../../shared/enums';
import { ProductModel } from '../../../../shared/models';
import { getRandomGradientClass } from '../../../../utils';
import './styles.scss';

interface ISongProps {
  product: ProductModel;
}

export const Song: FC<ISongProps> = ({
  product: {
    id,
    name,
    preview,
    categoryNames,
    price,
    currency,
    thumbnail,
    statement,
    sign,
    getThumbnailUrl,
    getPreviewSongUrl
  }
}) => {
  const { t } = useTranslation();

  return (
    <div className={`featured-song ${getRandomGradientClass()}`}>
      <div className={`product-summary ${getRandomGradientClass()}`}>
        <div className="backdrop" />
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${id}`}>
          <h2 className="text-heading2 text--lime product-name">{name}</h2>
        </Link>
        <hr />
        {preview?.filename && <AudioPlayer src={getPreviewSongUrl} />}
        <span className="text-body1 product-genre">{categoryNames}</span>
        <CartButton productId={id as string} productPrice={price as number} productCurrency={currency as CURRENCY} />
        {sign && (
          <img
            src={`${REACT_APP_API_ASSET_SERVER}/${sign.fieldname}/${sign.filename}`}
            className="mobile-sign"
            alt="sign"
          />
        )}
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${id}`}>
          <img className="product-image" src={getThumbnailUrl} alt={thumbnail?.filename} />
        </Link>
      </div>
      <div className="product-description">
        <h3 className="text-heading3">{t("Home.Artist's Statement.")}</h3>
        <hr />
        <div className="description">
          <p className="text-body1 statement" dangerouslySetInnerHTML={{ __html: statement || '' }} />
          {sign && (
            <img src={`${REACT_APP_API_ASSET_SERVER}/${sign.fieldname}/${sign.filename}`} className="sign" alt="sign" />
          )}
        </div>
      </div>
    </div>
  );
};
