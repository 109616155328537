// Dependencies
import { Flex, Spinner, useToast } from '@chakra-ui/core';
import classnames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Apis
import { OrderApi, ProductApi } from '../../apis';
// Component
import { Alert, AnimationOnScroll, IconButton, Pagination, ProductCard, Slider } from '../../components';
// Constants
import { ROUTES } from '../../constants';
import { PRODUCT_TYPE } from '../../shared/enums';
// Hooks
import { useDebounce } from '../../shared/hooks';
// Interfaces
import { IProduct, ISortOrder } from '../../shared/interfaces';
import { ProductModel } from '../../shared/models';
import { validateCurrency } from '../../utils';
// Utils
import { TabTitle, metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb } from '../../utils/generaltittlefunction';
// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import { getCart } from '../../store/selectors';

// Constant
const CntPerPage = 15;

// Export merchandise page
export const MerchandisePage: FC = () => {
  // States
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [priceRange, setPriceRange] = React.useState([0, 200]);
  const [sortOrder, setSortOrder] = useState<ISortOrder>(ISortOrder.ASC);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [currency, setCurrency] = useState<string>('');
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);

  // Get toast from hook
  const toast = useToast();
  const { t } = useTranslation();
  const cart = useSelector(getCart);

  // Get history from hook
  const history = useHistory();

  // Get params from hook
  const categoryId = history.location.search.split('category=')[1];

  // Calc page count
  const pageCnt = useMemo(() => {
    return Math.ceil(totalCnt / CntPerPage);
  }, [totalCnt]);

  // Fetch products
  const fetchProducts = useCallback(() => {
    setLoading(true);
    const query: any = {
      price: {
        $gte: priceRange[0],
        $lte: priceRange[1]
      }
    };

    if (categoryId) {
      query.category = categoryId;
    }

    query.type = PRODUCT_TYPE.MERCHANDISE;

    ProductApi.readAll({
      query,
      options: {
        limit: CntPerPage,
        skip: (pageNumber - 1) * CntPerPage,
        sort: {
          price: sortOrder
        }
      }
    })
      .then((res) => {
        setLoading(false);
        const { products, pagination } = res;
        setProducts(products.map((product: IProduct) => new ProductModel(product)));
        setTotalCnt(pagination.total);
      })
      .catch((err) => {
        setLoading(false);
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
        });
      });
  }, [categoryId, pageNumber, priceRange, sortOrder, toast]);

  // Price range handler
  const handlePriceRange = (values: number[]) => {
    setPriceRange(values);
  };

  // Sort handler
  const handleSort = () => {
    setSortOrder(sortOrder === ISortOrder.ASC ? ISortOrder.DESC : ISortOrder.ASC);
  };

  // Cart handler
  const handleCart = () => {
    history.push(ROUTES.CART);
  };

  // Debounce price range
  const debouncePriceRange = useDebounce(priceRange);

  // On price range, count per page, page number, sort order changed
  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [debouncePriceRange, pageNumber, sortOrder]);

  useEffect(() => {
    fetchProducts();
    OrderApi.getIpv4()
      .then((res: any) => {
        OrderApi.getDetailsFromIp(res.data.ip)
          .then((res) => {
            const timezone = res.data.timezone.split('/')[0];
            const currency = validateCurrency(timezone);
            setCurrency(currency);
          })
          .catch((err) => {
            console.log('🚀 ~ file: index.tsx:133 ~ .then ~ err:', err);
          });
      })
      .catch((err) => {
        console.log('🚀 ~ file: index.tsx:128 ~ .then ~ err:', err);
      });
  }, []);
  //dynamic handle title
  if (history.location.pathname === ROUTES.MERCHANDISE.INDEX) {
    TabTitle(t('Common.Merchandise - Digital Music Shopping Market Place'));
  }

  if (history.location.pathname === ROUTES.MERCHANDISE.INDEX) {
    metaTagByTitle(t('Common.Merchandise - Digital Music Shopping Market Place'));
  }
  metaTagByDesc(
    t('Common.VeepMusic is founded on values we all share and are ready to stand for.') +
      ' ' +
      t('Common.They bring us together well beyond our current products and technologies.') +
      ' ' +
      t(
        'Common.They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.'
      )
  );
  metaTagByKey(t('Common.VeepMusic, Nft, Hackers, Explore Through the Most Exciting Music NFT'));
  metaTagByWeb(`https://veepmusic.com${window.location.pathname}`);

  return (
    <div className={classnames('shop-page')}>
      <AnimationOnScroll animation="animate__bounce" delay={2}>
        <div className="page-title">
          <h2 className="text-heading2">
            {history.location.pathname === ROUTES.MERCHANDISE.INDEX ? t('Common.Merchandise') : t('Common.Products')}.
          </h2>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animation="animate__backInRight toolbar-container">
        <div className="toolbar">
          <h3 className="text-heading4">{t('Common.Item', { count: totalCnt })}</h3>
          <Slider min={0} max={1000} range={priceRange} onChange={handlePriceRange} />
          <div className="actions">
            <span
              className={classnames('badge', {
                active: cart?.products?.length
              })}
            >
              <span className="badge-text">{cart && cart.products?.length > 0 && cart?.products?.length}</span>
              <IconButton icon="shopping-bag" onClick={handleCart} />
            </span>
            <IconButton
              icon="filter"
              className={classnames('sort-button', {
                desc: sortOrder === ISortOrder.DESC
              })}
              onClick={handleSort}
            />
          </div>
        </div>
        <div className="divider" />
      </AnimationOnScroll>
      <div className="content">
        {isLoading ? (
          <Flex justifyContent="center" width={'100%'} alignItems="center" height={200}>
            <Spinner color="#00Ff00" size="xl" />
          </Flex>
        ) : (
          <div className="products">
            {products.length > 0 &&
              products.map((product) => (
                <AnimationOnScroll key={product.id} animation="animate__fadeIn" isSubElement>
                  <ProductCard product={product} productCurrency={currency} />
                </AnimationOnScroll>
              ))}
          </div>
        )}
        <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
      </div>
    </div>
  );
};
