import { Button, Flex, Spinner, useToast } from '@chakra-ui/core';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import { ProductApi } from '../../apis';
import { Video } from '../../assets/video/index';
import { Alert, AnimationOnScroll, ArticleView, IIcon, Icon } from '../../components';
import { REACT_APP_API_ASSET_SERVER, ROUTES } from '../../constants';
import { PRODUCT_TYPE } from '../../shared/enums';
import { IProduct } from '../../shared/interfaces';
import { ProductModel } from '../../shared/models';
import { getCategories, getUser } from '../../store/selectors';
import { Category, Event, Song, Trending } from './components';
import './styles.scss';

interface IElementor {
  icon: IIcon;
  title: string;
  description: string;
}

export const HomePage: FC = () => {
  const [songs, setSongs] = useState<ProductModel[]>([]);
  const [events, setEvents] = useState<ProductModel[]>([]);
  const [sliderEvents, setSliderEvents] = useState<ProductModel[]>([]);
  const [isPlayed, setIsPlayed] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);

  const toast = useToast();

  const history = useHistory();

  const categories = useSelector(getCategories);
  const user = useSelector(getUser);

  const elementorSection: Array<IElementor> = [
    {
      icon: 'pen-icon',
      title: t('Home.Sign Up'),
      description: t('Home.Signin up is fast, free and easy. All you need is a vaid email address and phone number.')
    },
    {
      icon: 'gift-icon',
      title: t('Home.Buy'),
      description: t('Home.Use debit or credit card to purchase from your favourite artist.')
    },
    {
      icon: 'cart-icon',
      title: t('Home.Use'),
      description: t('Home.Enjoy exciting utility like VIP concert tickets, IRL experience, and more.')
    },
    {
      icon: 'finger-print',
      title: t('Home.Gift'),
      description: t('Home.1-click to gift your Item to anyone with an email address.')
    }
  ];

  const handleTogglePlay = () => {
    if (isPlayed) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setIsPlayed(!isPlayed);
  };

  const handleVideoEnded = () => {
    setIsPlayed(false);
  };

  const handleGoToEvents = () => {
    history.push(ROUTES.EVENTS.INDEX);
  };

  const handleGotoProducts = () => {
    history.push(ROUTES.PRODUCTS.INDEX);
  };

  const handleGotoDashboard = () => {
    history.push(ROUTES.PROFILE.DASHBOARD);
  };

  const handleGotoSignUp = () => {
    history.push(ROUTES.AUTH.SIGN_UP);
  };

  const showToast = useCallback(
    (msg: string) =>
      toast({
        position: 'top-right',
        render: ({ onClose }) => <Alert message={msg} color="red" onClose={onClose} />
      }),
    // eslint-disable-next-line
    []
  );

  const downloadProducts = useCallback(() => {
    const downloadSongs = ProductApi.readAll({
      query: {
        type: PRODUCT_TYPE.SONG,
        isFeatured: true
      }
    })
      .then((res) => {
        setSongs(res.products.map((product: IProduct) => new ProductModel(product)));
        setSongs(res.products.map((product: IProduct) => new ProductModel(product)));
      })
      .catch((err) => {
        showToast(err.msg);
      });

    const downloadEvents = ProductApi.readAll({
      query: {
        type: PRODUCT_TYPE.VIRTUAL_EVENT,
        isFeatured: true
      }
    })
      .then((res) => {
        setEvents(res.products.map((product: IProduct) => new ProductModel(product)));
      })
      .catch((err) => {
        showToast(err.msg);
      });

    return Promise.all([downloadSongs, downloadEvents]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    downloadProducts().finally(() => setLoading(false));
    ProductApi.readAll()
      .then((res) => {
        setSliderEvents(res.products.map((product: IProduct) => new ProductModel(product)));
      })
      .catch((err) => {
        showToast(err.msg);
      });
    // eslint-disable-next-line
  }, [showToast]);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    vertical: true,
    verticalSwiping: true,
    pauseOnHover: false
  };

  return (
    <div className="home-page">
      <div className="lead">
        <video src={Video} muted={true} autoPlay={true} loop={true} className="video-content"></video>
        <div className="lead-title">
          <h1 className="text-heading1 lead-title-nft">
            {t('Home.Limited Edition')}
            <span>
              {' '}
              {t('Home.Music')} <span className="nft">Item</span>'s
            </span>
          </h1>
          <div className="lead-action">
            <p className="text-body2">
              {t('Home.Welcome to our online marketplace where you can buy and collect unique Music Items.')}
              {t(
                'Home.Our carefully curated selection features rare and exclusive tracks from talented musicians and producers around the world.'
              )}
            </p>
            <div className="lead-action-button">
              <Button className="lead-explore" onClick={handleGotoProducts}>
                {t('Home.Explore')}
              </Button>
              <Button className="lead-event" onClick={handleGoToEvents}>
                {t('Home.Event')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="elementor-container">
        {elementorSection.map((item, index) => (
          <div key={index} className="elementor-box">
            <Icon name={item.icon} height={50} width={50} />
            <h1 className="text-heading3">{item.title} </h1>
            <p className="text-body2 text--center">{item.description}</p>
          </div>
        ))}
      </div>
      <div className="nft-section">
        <div className="nft-section-left">
          <h3 className="text-body2 text--cyan">{t('Collect Items from your favourite Music')}</h3>
          <p className="text-body1">
            {t(
              'VeepMusic is the mass-consumer Web3 company connecting the next 100M non-native fans to iconic artists.'
            )}{' '}
            {t(
              'VeepMusic technology enables global brands and enterprises to reimagine commerce, loyalty and engagement, allowing consumers to seamlessly collect or trade digital assets with no prior music expertise.'
            )}
          </p>
          <div className="nft-button">
            <Button className="nft-button-join">{t('Home.Join for Free')}</Button>
            <Button className="nft-button-explore">{t('Home.Explore')}</Button>
          </div>
        </div>
        <div className="nft-section-right">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Slider {...settings}>
            {songs.slice(0, 10).map((item: ProductModel, index) => (
              <div key={index} className="img">
                <img
                  className="product-image"
                  src={`${REACT_APP_API_ASSET_SERVER}/${item.thumbnail?.fieldname}/${item.thumbnail?.filename}`}
                  alt={item.thumbnail?.filename}
                />
              </div>
            ))}
          </Slider>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Slider {...settings} rtl={true}>
            {sliderEvents.slice(0, 10).map((item, index) => (
              <div key={index} className="img">
                <img
                  className="product-image"
                  src={`${REACT_APP_API_ASSET_SERVER}/${item.thumbnail?.fieldname}/${item.thumbnail?.filename}`}
                  alt={item.thumbnail?.filename}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="songs-events">
        <AnimationOnScroll animation="animate__zoomIn" className="section-title">
          <h2 className="text-heading2">
            <span>{t('Home.Latest')}</span> <span>{t('Home.Songs')}</span> <span>&</span>{' '}
            <span>{t('Home.Events')}</span>
          </h2>
        </AnimationOnScroll>
        <div className="events-animation-wrapper">
          {events.map((event, index) => (
            <AnimationOnScroll key={index} animation="animate__backInRight" delay={1} className="backdrop-blur">
              <Event key={index} event={event} />
            </AnimationOnScroll>
          ))}
        </div>
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height={200}>
            <Spinner color="#00Ff00" size="xl" />
          </Flex>
        ) : (
          songs.map((product, index) => (
            <AnimationOnScroll key={index} animation="animate__backInRight">
              <Song product={product} />
            </AnimationOnScroll>
          ))
        )}
      </div>
      <Trending />
      <div className="section category-section">
        <AnimationOnScroll animation="animate__zoomIn" isSubElement>
          <h2 className="text-heading2 section-title">{t('Home.Browse by Category')}</h2>
        </AnimationOnScroll>
        <div className="categories">
          {categories.slice(0, 4).map((category, index) => (
            <AnimationOnScroll key={index} animation="animate__fadeIn" isSubElement>
              <Category category={category} />
            </AnimationOnScroll>
          ))}
        </div>
        <Button
          className="view-all"
          onClick={() => {
            history.push('/category');
          }}
        >
          {t('Home.View All')}
        </Button>
      </div>
      <div className="section meet-section">
        <AnimationOnScroll animation="animate__zoomIn" isSubElement className={'title-container'}>
          <h2 className="text-heading2 section-title">{t('Home.Meet VeepMusic Clubhouse')}</h2>
          <p className="text-body1 text--center section-subtitle">
            {t('Home.Listen to your favourite Artist, live, in Metaverse!')}
          </p>
        </AnimationOnScroll>
        <div className="meet-section-content">
          <div className="meet-video">
            <div className="meet-video-description">
              <img src="/images/logo-filled.png" alt="logo" />
              <p className="text-body1">
                {t('Home.VeepMusic Clubhouse | Listen to your favourite Artist, live, in Metaverse!')}
              </p>
            </div>
            <video ref={videoRef} onClick={handleTogglePlay} onEnded={handleVideoEnded} />
            {isPlayed ? (
              <Icon name="video-pause" className="video-action" />
            ) : (
              <Icon name="video-play" className="video-action" />
            )}
          </div>
          <AnimationOnScroll animation="animate__fadeIn" isSubElement className="meet-description">
            <p className="text-body1">
              {t('Home.In')} <span className="text--cyan">{t("Home.VeepMusic's Clubhouse")}</span>{' '}
              {t('Home.you can join to other fans of your favourite Artist and enjoy the Music!')}
              <br />
              <br />
              {t('Home.All you need to do is just book your ticket and join')}{' '}
              <span className="text--cyan">{t('Home.To VeepMusic Clubhouse')}</span> {t('Home.on time!')}
            </p>
            <div className="meet-actions">
              <Button className="d-button d-button--cyan">{t('Home.CLUBHOUSE')}</Button>
              <Button className="d-outlined-button d-button--cyan" onClick={handleGoToEvents}>
                {t('Home.EVENTS LIST')}
              </Button>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="section explore-section">
        <h1 className="text-heading1 text--center">{t("Explore Through the Most Exciting Music Item's")}</h1>
        <div className="explore-action">
          <Button className="d-button" onClick={user?.id ? handleGotoProducts : handleGotoSignUp}>
            {user?.id ? t('Home.Explore') : t('Home.Sign Up')}
          </Button>
          <Button className="d-outlined-button" onClick={user?.id ? handleGotoDashboard : handleGotoProducts}>
            {' '}
            {user?.id ? t('Home.Go to Dashboard') : t('Home.Explore')}
          </Button>
        </div>
      </div>
    </div>
  );
};
