import React, { useState } from 'react';
import './style.scss';
import { BasicInfo, FaceRecognition, Info, UploadCard } from '../../assets/icons';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { CustomCheckBox } from '../../components/CustomCheckBox';
import CalendarPage from '../../components/Calendar';
import { Button } from '@chakra-ui/core';

const KYCPage = () => {
  const [isCheckOn, setIsCheckOn] = useState<boolean>(false);
  // Get history from hook
  const history = useHistory();
  const idVerificationHandler = () => {
    history.push(ROUTES.KYC.VERIFY);
  }
  return (
    <div className="kyc-container-fluid">
      <div className="container">
        <div className="title text-gemunu">ID Verification</div>
        <div className="process">
          <div className="subtitle">How to Verify</div>
          <div className="d-flex stage-view full-width justify-between">
            <BasicInfo />
            <UploadCard />
            <FaceRecognition />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="subtitle">Attention</div>
          <div className="d-flex info">
            <Info />
            <div className="desc">
              Please make sure that the info provided is identical to your ID info, and these information cannot be altered once the ID verification is passed.
            </div>
          </div>
          <div className="d-flex info">
            <Info />
            <div className="desc">
              If you didn't install camera on your computer, please scan the code when uploading the ID info and use your mobile phone for verification.
            </div>
          </div>
          <div className="d-flex info">
            <Info />
            <div className="desc">
              The audit results will be send to your Email in 1-3 working day, please check your mailbox.
            </div>
          </div>
        </div>
        <div className="actions-box">
          <div className="d-flex">
            <CustomCheckBox isCheck={isCheckOn} toggleCheck={() => setIsCheckOn(!isCheckOn)} />
            <div className="check-label">
              I promise that the information provided is my own, and there is no pirating of other people's information.
            </div>
          </div>
          <Button className="btn d-flex justify-center align-center" isDisabled={!isCheckOn} onClick={idVerificationHandler}>
            <div>I'm all set for ID Verification</div>
          </Button>
        </div>

      </div>
    </div>
  );
};

export default KYCPage;
