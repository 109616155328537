// Dependencies
import { Button, useToast } from '@chakra-ui/core';
import classnames from 'classnames';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Apis
import { CartApi } from '../../apis';
// Components
import { Alert, Icon } from '../../components';
// Interfaces
import { CURRENCY } from '../../shared/enums';
// Store
import { addToCart } from '../../store/actions';
import { getCart, getUser } from '../../store/selectors';
// Styles
import './styles.scss';

interface ICartButtonProps {
  productId: string;
  productPrice: number;
  productCurrency: CURRENCY;
  color?: 'lime' | 'cyan';
  currency?: string;
}

// Create cart button
export const CartButton: FC<ICartButtonProps> = ({
  currency,
  productId,
  productPrice,
  productCurrency,
  color = 'lime'
}) => {
  // Get cart from store
  const cart = useSelector(getCart);

  // Get user from store
  const user = useSelector(getUser);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get toast from hook
  const toast = useToast();
  const { t } = useTranslation();

  // Check product exists in cart
  const isInCart = useMemo(() => {
    let flag = false;
    if (cart && cart.products && cart.products.length > 0) {
      flag = cart.products.some(({ id }) => id === productId);
    }

    return flag;
  }, [productId, cart]);

  // Toggle cart handler
  const handleToggleCart = () => {
    if (user) {
      if (isInCart) {
        CartApi.removeFromCart({
          productId: productId,
          fingerprint: user.id,
          userId: user.id
        })
          .then((res) => {
            dispatch(addToCart(res.cart));
            toast({
              position: 'top-right',
              render: ({ onClose }) => (
                <Alert message={t('Message.Removed from your cart!')} color="yellow" onClose={onClose} />
              )
            });
          })
          .catch((err) => {
            toast({
              position: 'top-right',
              render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
            });
          });
      } else {
        CartApi.addToCart({
          productId: productId,
          fingerprint: user.id,
          price: productPrice,
          currency: productCurrency,
          userId: user.id
        })
          .then((res) => {
            dispatch(addToCart(res.cart));
            toast({
              position: 'top-right',
              render: ({ onClose }) => (
                <Alert message={t('Message.Successfully added to the cart!')} onClose={onClose} />
              )
            });
          })
          .catch((err) => {
            toast({
              position: 'top-right',
              render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
            });
          });
      }
    } else {
      toast({
        position: 'top-right',
        render: ({ onClose }) => (
          <Alert message={t('Message.Login to the platform first!')} color="cyan" onClose={onClose} />
        )
      });
    }
  };

  // Return cart button
  return (
    <div
      className={classnames(`d-cart-button`, {
        'd-cart-button--added': isInCart
      })}
    >
      <Button className={`d-button d-button--${color} toggle-cart-button`} onClick={handleToggleCart}>
        {isInCart ? (
          <>
            <h1 className="tooltip">{t('Common.Remove Product from Cart by clicking again')}</h1>
            {t('Common.Added to Cart')} <Icon name="shopping-bag" />
          </>
        ) : (
          t('Common.Add to Cart')
        )}
      </Button>
      <h4 className="text-heading4 product-price">
        {productCurrency}
        {(Math.round(productPrice * 100) / 100).toFixed(2)}
      </h4>
    </div>
  );
};
