// Dependencies
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Components
import { AnimationOnScroll } from '../../../components';

// Styles
import './styles.scss';
import { getTransactionDetails } from '../../../apis/product.api';
import { Button } from '@chakra-ui/core';

// Export order detail page
export const OrderNftDetailPage: FC = () => {
  // States
  const [nft, setNft] = useState<any>({});

  // Get params from hook
  const hash = useParams<{ tx: string }>();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get('name');

  const getTransaction = useCallback(async () => {
    const res = await getTransactionDetails(hash?.tx as string);
    setNft(res.nft);
  }, [hash]);

  const handleGoBack = (e: any) => {
    window.history.go(-1);
  }

  useEffect(() => {
    if (hash?.tx) {
      getTransaction();
    }
  }, [hash, getTransaction]);

  // Return order nft detail page
  return (
    <div className="order-nft-detail-page">
      <Button onClick={handleGoBack} className="d-outlined-button">
        Back
      </Button>
      <AnimationOnScroll animation="animate__bounce" delay={2.5}>
        <div className="page-title">
          <h2 className="text-heading2">{name}</h2>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animation="animate__slideInUp" delay={1.5} isSubElement className="detail-panel">
        <div className='detail-item'>
          <p className="text-body1">Network:</p>
          <p className="text-body1">{nft?.details?.chain}</p>
        </div>
        <hr className="divider" />
        <div className='detail-item'>
          <p className="text-body1">TokenId:</p>
          <p className="text-body1">{nft?.tokenId}</p>
        </div>
        <hr className="divider" />
        <div className='detail-item'>
          <p className="text-body1">To:</p>
          <p className="text-body1">{nft?.details?.to}</p>
        </div>
        <hr className="divider" />
        <div className='detail-item'>
          <p className="text-body1">TxHash:</p>
          <p className="text-body1">{nft?.details?.transactionHash}</p>
        </div>
        <hr className="divider" />
      </AnimationOnScroll></div>
  );
};
