// Dependencies
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// Components
import { Alert } from '../../components';
import { useToast } from '@chakra-ui/core';
// Apis
import { ProductApi } from '../../apis';

// Constants
import { ROUTES } from '../../constants';

// Interfaces
import { PRODUCT_TYPE } from '../../shared/enums';
import { ProductModel } from '../../shared/models';

// Store
import { toggleFavorite } from '../../store/actions';
import { getFavorites, getUser } from '../../store/selectors';

// Styles
import { metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb, TabTitle } from '../../utils/generaltittlefunction';
import { ProductDetailEventPage } from './Event';
import { ProductDetailSongPage } from './Song';

// Export product-details page
export const ProductDetailPage: FC = () => {
    // States
    const [product, setProduct] = useState<ProductModel>();

    // Get product id from hook
    const { id: productId } = useParams<{ id: string }>();

    // Get toast from hook
    const toast = useToast();

    // Get history from hook
    const history = useHistory();

    // Get dispatch from hook
    const dispatch = useDispatch();

    // Get user from store
    const user = useSelector(getUser);

    // Get favorites from store
    const favorites = useSelector(getFavorites);

    // Check favorite
    const isFavorite = useMemo(() => favorites?.includes(productId), [favorites, productId]);

    // Data fetcher
    const fetchData = (productId: string) => {
        ProductApi.read(productId)
            .then((res) => {
                setProduct(new ProductModel(res.product));
            })
            .catch((err) => {
                toast({
                    position: 'top-right',
                    render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
                });
            });
    };

    // Toggle favorite handler
    const handleToggleFavorite = () => {
        if (user) {
            ProductApi.toggleFavorite({
                fingerprint: user.id,
                productId: product?.id as string
            })
                .then(() => {
                    if (isFavorite) {
                        toast({
                            position: 'top-right',
                            render: ({ onClose }) => <Alert message="Removed from your cart!" color="yellow" onClose={onClose} />
                        });
                    } else {
                        toast({
                            position: 'top-right',
                            render: ({ onClose }) => <Alert message="Successfully added to favorite products" onClose={onClose} />
                        });
                    }
                    dispatch(toggleFavorite(productId));
                })
                .catch((err) => {
                    toast({
                        position: 'top-right',
                        render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
                    });
                });
        } else {
            toast({
                position: 'top-right',
                render: ({ onClose }) => <Alert message="Login to the platform first!" color="cyan" onClose={onClose} />
            });
        }
    };

    // Next handler
    const handleNext = () => {
        if (product?.next) {
            history.push(`${ROUTES.PRODUCTS.PREFIX}/${product.next.id}`);
        }
    };

    // On product id changed
    useEffect(() => {
        fetchData(productId);
        // eslint-disable-next-line
    }, [productId]);

    TabTitle(`${product?.name} - Digital Music Shopping Market Place`)
    metaTagByTitle(`${product?.name} - Digital Music Shopping Market Place`)
    metaTagByDesc(`${product?.description}`)
    metaTagByKey('VeepMusic, Nft, Hackers, Explore Through the Most Exciting Music NFT')
    metaTagByWeb(`https://veepmusic.com${window.location.pathname}`)
    if (product) {
        if (product?.type === PRODUCT_TYPE.VIRTUAL_EVENT) {
            return (
                <ProductDetailEventPage product={product} handleToggleFavorite={handleToggleFavorite} handleNext={handleNext} isFavorite={isFavorite} />
            );
        }
        else if (product?.type === PRODUCT_TYPE.SONG || PRODUCT_TYPE.IMAGES || PRODUCT_TYPE.VIDEOS || PRODUCT_TYPE.MERCHANDISE) {
            return (
                <ProductDetailSongPage product={product} handleToggleFavorite={handleToggleFavorite} handleNext={handleNext} isFavorite={isFavorite} />
            )
        }
    }
    return (<></>);
};
