import { HttpV2 } from './http';

export const getGalleriesByArtist = (artistId: string, query: any) => {
  return HttpV2.get(`/gallery/${artistId}`, query);
};

export const readById = (galleryId: string) => {
  return HttpV2.get(`/gallery/detail/${galleryId}`);
};



